@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Titillium Web", sans-serif;
}

:root {
	--mainColor: #29335c;
	--mainColorLight: #5767aa;
	--secondaryColor: #db2b39;
	--textColor: #eee;
}

.navbar-top-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
	background-color: var(--mainColor);
	color: var(--textColor);
}

.navbar-top a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
}

.navbar-top a:hover {
	color: var(--secondaryColor);
}

.navbar-top-header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

.navbar-top-header div,
.navbar-top {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
	.navbar-top-header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	.navbar-top-header .navbar-top {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 1s;
	}

	.navbar-top-header .responsive_nav {
		transform: translateY(100vh);
		z-index: 10;
	}

	.navbar-top .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	.navbar-top a {
		font-size: 1.5rem;
	}
}
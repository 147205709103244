@import url(https://fonts.googleapis.com/css?family=Montserrat);

// ------------- MIXINS ------------- //
@mixin transition($time, $property: all, $easing: ease-in) {
    transition: $property $time $easing;
}

// ------------- VARIABLES ------------- //
$parallax-offset: 65vh;
$content-offset: 40vh;
$content-main-height: 500px;
$transition-speed: 1.2s;
$slide-number: 3;

.background {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
    will-change: transform;
    backface-visibility: hidden;
    height: 100vh + $content-offset;
    position: fixed;
    width: 100%;
    transform: translateY($parallax-offset);
    @include transition($transition-speed, all, cubic-bezier(0.22, 0.44, 0, 1));
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.3);
    }
  }

  /* Set stacking context of slides */
@for $i from 1 to ($slide-number + 1) {
    .background:nth-child(#{$i}) {
        z-index: ($slide-number + 1) - $i; 
    }
}

.background_1 {
  background-image: url("./assets/background_image_1.jpg");
}

.background_2 {
  background-image: url("./assets/background_image_2.jpg");
}

.background_3 {
  background-image: url("./assets/background_image_3.jpg");
}

.content {
  &-main {
    height: $content-main-height
  }
  &-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-flow: column nowrap;
  color: #fff;
  font-family: Montserrat;
  text-transform: uppercase;
  transform: translateY($content-main-height / 2);
  will-change: transform;
  backface-visibility: hidden;
  @include transition($transition-speed + .5, all, cubic-bezier(0.22, 0.44, 0, 1));
  }
  &-title {
    font-size: 12vh;
    line-height: 1.4;
  }
}

// ------------- SET TRANSFORM VALUES ------------- //

.background.up-scroll {
  transform: translate3d(0,-$parallax-offset / 2,0);
  .content-wrapper {
    transform: translateY($parallax-offset / 2);
  }
  + .background {
    transform: translate3d(0,$parallax-offset,0);
      .content-wrapper {
        transform: translateY($parallax-offset);
      }
    }
}

.background.down-scroll {
  transform: translate3d(0,-(100vh + $parallax-offset),0);
  .content-wrapper {
    transform: translateY($content-offset);
  }
  + .background:not(.down-scroll) {
    transform: translate3d(0,-$parallax-offset / 2,0);
      .content-wrapper {
        transform: translateY($parallax-offset / 2);
      }
  }
}